
.page-title{
    position: relative;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px 0px 80px;
    height: 255px !important;
    
}
.page-title:before {
    content:'';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(5, 4, 4, 0.3);
}
.auto-container{
    position: static;
    max-width: 1200px;
    padding: 120px 15px;
    margin: 0px auto;
}
.page-title .title-box{
    position:realative;
    padding: 10px 15px 15px;
    z-index: 10;
    display: inline-block;
   
}

.page-title .title-box h2{
    position: relative;
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2em;
    margin-bottom: 10px;
}

@media only screen and (min-width: 200px) and (max-width: 767px){
    .page-title{
        height: 200px !important;
        padding: 0px 0px 80px;
    }
    .page-title .title-box h2{
        font-size: 30px;
    }
}
    