/* Nav.css */
.colortext {
  color: black;
  transition: color 0.3s ease;
}

.colortext,colortextFooter:hover {
  color: #118886;
}
.navbar-brand img {
  width: 130px;
}

.colortextFooter {
  color: white;
  transition: color 0.3s ease;
}

.colortextFooter:hover {
  color: #118886;
}

/* Para los íconos del menú móvil */
.inline-flex.items-center.justify-center {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.inline-flex.items-center.justify-center:hover {
  background-color: #118886; /* Color de fondo al pasar el ratón */
  color: white; /* Color del ícono al pasar el ratón */
}

